import cn from "classnames";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";

import AookExistingModal from "../../../JournalDone/components/JournalExecTicket/components/Aook/AookExistingModal/AookExistingModal";
import AosrExistingModal from "../../../JournalDone/components/JournalExecTicket/components/Aosr/AosrExistingModal/AosrExistingModal";
import CommonFilesModal from "components/modals/ExpenditureFilesModal/CommonFilesModal";

import JournalEditKs2Modal from "../../JournalActingModal/JournalEditKs2Modal";
import JournalKs3EditModal from "../../JournalActingModal/JournalKs3EditModal";
import JournalActingFilesRow from "./files/JournalActingFilesRow";
import { useJournalActingFiles } from "./files/useJournalActingFiles";
import ksIcon from "./ksIcon";
import { useActingActs } from "./useActingActs";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import FileViewer from "widgets/FileViewer/FileViewer";

import { VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2 } from "constants/permissions/manufacturingPermissions";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import usePermission from "hooks/usePermission";

import { splitThousands } from "utils/formatters/splitThousands";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import CrossInCircle from "images/icons/CrossInCircle";

import styles from "./JournalActingExpenditure.module.scss";

interface IProps {
  type: "ks2" | "estimate";
  isFirstInParent?: boolean;
  expenditure: IExpenditureInActing;
  estimateId: number;
  sectionId: number;
  sectionName?: string;
}

const JournalActingExpenditure: React.FC<IProps> = ({
  type,
  isFirstInParent,
  expenditure,
  estimateId,
  sectionId,
  sectionName,
}) => {
  const isEstimate = type === "estimate";
  const dispatch = useDispatch();

  const haveFormationKs2Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2);

  const [isEditKs2, setIsEditKs2] = useState(false);
  const [isEditKs3, setIsEditKs3] = useState(false);

  const checkHandler = useCallback(() => {
    dispatch(actingActions.selectExpenditure(expenditure.id, !expenditure.isSelected, sectionId, estimateId));
  }, [estimateId, expenditure, sectionId]);

  const isDisabledByConstrControl = expenditure.cc_confirmed === false;

  const [filesRowOpen, setFilesRowOpen] = useState(false);
  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const { files, isLoading, isDelete, setIsDelete, deleteDocument, downloadHandler, onSubmit, onUpload, cacheKey } =
    useJournalActingFiles({
      sectionId,
      isOpen: filesRowOpen || filesModalOpen,
      itemId: expenditure.id,
      expenditure: expenditure,
    });

  const { activeAook, activeAosr, isAookOpen, isAosrOpen, setIsAookOpen, setIsAosrOpen } = useActingActs(expenditure);

  const [activeFileIndex, setActiveFileIndex] = useState(-1);
  const onlyDocumentsForViewer = useMemo(() => {
    return files?.filter((el) => el.type !== "file");
  }, [files]);

  return (
    <>
      {isFirstInParent && sectionName && (
        <div className={styles.parent}>
          <div />
          {sectionName}
        </div>
      )}
      <TableReusableRow
        isHoverable={false}
        className={cn(styles.row, { [styles.rowInKs2]: !isEstimate })}
        containerClassName={cn(styles.wrapper, filesRowOpen && styles.withoutBorder)}
      >
        <TableReusableCell className={styles.checkCol}>
          <div className={styles.checkContent}>
            <span>{expenditure.row_number}</span>
            {!isDisabledByConstrControl && isEstimate && haveFormationKs2Permission && (
              <Checkbox onCheck={checkHandler} checked={!!expenditure.isSelected} />
            )}
          </div>
        </TableReusableCell>
        <TableReusableCell className={styles.nameCol}>
          <span className={styles.name} title={expenditure.name}>
            {expenditure.name}
          </span>
          <div className={styles.nameBottom}>
            <BlueLabel className={styles.justification} blueFont>
              {expenditure.justification}
            </BlueLabel>
            {activeAosr && (
              <BlueLabel className={cn(styles.justification, styles.aosr)} blueFont onClick={() => setIsAosrOpen(true)}>
                АОСР от {moment(activeAosr?.act_date).format("DD.MM.YYYY")}
              </BlueLabel>
            )}
            {activeAook && (
              <BlueLabel className={cn(styles.justification, styles.aosr)} blueFont onClick={() => setIsAookOpen(true)}>
                АООК от {moment(activeAook?.act_date).format("DD.MM.YYYY")}
              </BlueLabel>
            )}
          </div>
        </TableReusableCell>
        <TableReusableCell isCentered>{expenditure.measure}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.completed_count}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.left_count}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.todo_count}</TableReusableCell>
        <TableReusableCell isRighted>{splitThousands(expenditure.amount, false, false)}</TableReusableCell>
        {isEstimate && (
          <TableReusableCell isCentered onClick={() => expenditure.act_ks2 && setIsEditKs2(true)}>
            <div
              className={cn(styles.ks, { [styles.activeKs]: expenditure.act_ks2 })}
              title={expenditure.act_ks2?.title}
            >
              {ksIcon}
              {expenditure.act_ks2 && <span>{expenditure.act_ks2.title}</span>}
            </div>
          </TableReusableCell>
        )}
        {isEstimate && (
          <TableReusableCell onClick={() => expenditure.act_ks3 && setIsEditKs3(true)}>
            <div
              className={cn(styles.ks, { [styles.activeKs]: expenditure.act_ks3 })}
              title={expenditure.act_ks3?.title}
            >
              {ksIcon}
              {expenditure.act_ks3 && <span>{expenditure.act_ks3.title}</span>}
            </div>
          </TableReusableCell>
        )}
        {isEstimate && (
          <TableReusableCell>
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <FilesClipButton onClick={() => setFilesModalOpen(true)} />
              {!!expenditure.files_count && (
                <ExpandPillTrigger
                  toggleExpand={() => {
                    setFilesRowOpen((p) => !p);
                  }}
                  label={expenditure.files_count}
                  isExpanded={filesRowOpen}
                />
              )}
            </div>
          </TableReusableCell>
        )}
      </TableReusableRow>
      {filesRowOpen && <JournalActingFilesRow files={files} />}
      <CommonFilesModal
        invalidateKey={cacheKey}
        key={expenditure.expenditure_type}
        isPending={isLoading}
        type="expenditure"
        expenditureType={expenditure.expenditure_type}
        onSubmit={onSubmit}
        onUpload={onUpload}
        fileTypeForFiltering="file"
        isOpen={filesModalOpen}
        onDirectlyDelete={deleteDocument}
        onClose={() => setFilesModalOpen(false)}
        name={expenditure?.name}
        attachments={files as any}
        isFileViewer
        renderFileRow={(file, i) => (
          <div className={styles.tableRow} key={file.id} onClick={() => setActiveFileIndex(i)}>
            <TableReusableCell isNoBreak>{file.type_display} </TableReusableCell>
            <TableReusableCell isNoBreak>{getFileNameWithoutExtension(file.originalname)} </TableReusableCell>
            <TableReusableCell className={styles.files} onClick={() => downloadHandler(file)}>
              <DownloadIcon />
            </TableReusableCell>
            <TableReusableCell>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDelete(file);
                }}
                className={styles.delete}
              >
                <CrossInCircle />
              </div>
            </TableReusableCell>
          </div>
        )}
        customTableHead={
          <div className={styles.tableHead}>
            <div>Тип</div>
            <div>Имя</div>
          </div>
        }
      />
      <FileViewer
        files={onlyDocumentsForViewer as any}
        isOpen={activeFileIndex > -1}
        onClose={() => {
          setActiveFileIndex(-1);
        }}
        startIndex={activeFileIndex}
      />
      <ConfirmModal
        isOpen={!!isDelete}
        onClose={() => setIsDelete(null)}
        action={() => deleteDocument()}
        variant="secondary"
        title="Подтвердите удаление"
      />
      {expenditure.act_ks2 && (
        <JournalEditKs2Modal isOpen={isEditKs2} onClose={() => setIsEditKs2(false)} ks2Id={expenditure.act_ks2?.id} />
      )}
      {expenditure.act_ks3 && (
        <JournalKs3EditModal isOpen={isEditKs3} onClose={() => setIsEditKs3(false)} ks3Id={expenditure.act_ks3?.id} />
      )}
      <AosrExistingModal
        canEdit={false}
        canImportAosr={false}
        isOpen={isAosrOpen}
        onClose={() => setIsAosrOpen(false)}
        ticket={{ expenditure } as any}
        sectionId={sectionId}
        externalActPreview={activeAosr}
      />
      <AookExistingModal
        key={activeAook?.id}
        isOpen={isAookOpen}
        onClose={() => setIsAookOpen(false)}
        sectionId={sectionId}
        ticket={{ expenditure } as any}
        activeAook={activeAook!}
      />
    </>
  );
};

export default JournalActingExpenditure;

//TODO вынести
export function getFileNameWithoutExtension(fullname: string = "") {
  const [name] = fullname?.split(".");

  return name ?? "";
}
