import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CLEAR_MATERIAL_EXPENDITURES,
  CLEAR_MATERIAL_SUBSECTIONS,
  getMaterialsExpenditures,
  isLoadingSectionSelector,
  materialExpendituresSelector,
  materialSectionsSelector,
} from "redux/modules/common/building/materials/materials";

import Expenditure from "../components/Expenditure/Expenditure";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import materialsIcon from "images/icons/navigation/materials.svg";

import styles from "./../Estimate.module.scss";

interface IProps {
  sectionId: number;
  onClick: (sectionId: number) => void;
  onBack: () => void;
  isSwitch: boolean;
}

const ExpendituresLevel: React.FC<IProps> = ({ sectionId, onBack, onClick, isSwitch }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSectionSelector);
  const materialSubExpenditures = useSelector(materialExpendituresSelector);
  const materialSections = useSelector(materialSectionsSelector);

  useLayoutEffect(() => {
    dispatch(getMaterialsExpenditures(objectId, sectionId));
  }, [objectId, sectionId]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_MATERIAL_EXPENDITURES });
    };
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className={styles.containerBtnActive}>
        <ButtonBack onClick={onBack} className={styles.button} />
        <span>{materialSubExpenditures?.name}</span>
      </div>
      {materialSubExpenditures.expenditure_count === 0 ? (
        <EmptyPlaceholder img={materialsIcon} />
      ) : (
        <Expenditure
          objectId={objectId}
          materialArray={materialSubExpenditures.expenditures}
          isSwitch={isSwitch}
          data={materialSections}
        />
      )}
    </>
  );
};

export default ExpendituresLevel;
