//@ts-nocheck
import { message } from "antd";
import _, { debounce } from "lodash";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ICreateGroupValues, ISelectedProduct } from "../../../../pages/Building/types";

import { calculateTotalsFromExpenditures } from "../lib/calculateTotalsFromExpenditures";
import { groupApi } from "../model/api";
import { ExpendituresTable } from "./ExpendituresTable/ExpendituresTable";
import { Footer } from "./Footer/Footer";
import { ForContractorSwitcher } from "./ForContractorSwitcher/ForContractorSwitcher";
import { GroupAmounts } from "./GroupAmounts/GroupAmounts";
import { GroupInfo } from "./GroupInfo/GroupInfo";
import { Header } from "./Header/Header";
import { PaymentTerms } from "./PaymentTerms/PaymentTerms";
import Modal from "shared/ui/modal/Modal";

import {
  VIEW_MANUFACTURING_PROJECT_EDIT_GROUPS,
  VIEW_MANUFACTURING_PROJECT_UNGROUP_GROUPS,
} from "../../../../../constants/permissions/manufacturingPermissions";
import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import { IGroupInfo, IGroupTotals } from "../types";
import { VIEW_BUILDING_EDIT_GROUPS, VIEW_BUILDING_UNGROUP_GROUPS } from "constants/permissions/constructingPermissions";

import { useCommonModulesPermissions } from "../../../../../hooks/useCommonModulesPermissions";

import styles from "./ExpenditureGroupModal.module.scss";

interface IProps {
  isCreation?: boolean;
  creationExpenditures?: ISelectedProduct[];
  isOpen: boolean;
  onClose: () => void;
  group: IGroupInfo | null;
  isShowButtons?: boolean;
  handleUngroup?: () => void;
  handleEdit?: (data: Partial<IGroupInfo>, errorCallback?: () => void) => void;
  handleCreate?: (data: ICreateGroupValues) => void;
  isPending?: boolean;
}

const DEBOUNCE_MS = 500;

const debouncedCalculateAllRequest = debounce(
  (objectId: string, amount: string, expenditures: IExpenditure[], callback?: (data: any) => void) => {
    groupApi.calculateAmount(objectId, amount, expenditures, callback);
  },
  DEBOUNCE_MS
);

const debouncedCalculateTotalsRequest = debounce(
  (
    objectId: string,
    totals: IGroupTotals,
    changedTotalName: keyof Omit<IGroupTotals, "all">,
    expenditures: IExpenditure[],
    callback?: (data: any) => void
  ) => {
    groupApi.calculateTotals(objectId, totals, changedTotalName, expenditures, callback);
  },
  DEBOUNCE_MS
);

const ExpenditureGroupModal: React.FC<IProps> = ({
  isCreation = false,
  creationExpenditures = [],
  isOpen,
  onClose,
  group,
  isShowButtons,
  handleUngroup,
  handleEdit,
  handleCreate,
  isPending,
}) => {
  const [isEditing, setIsEditing] = useState(() => isCreation);
  const [isMarketPrice, setIsMarketPrice] = useState(false);
  const { objectId } = useParams();

  const initialExpenditures = useMemo(() => {
    if (isCreation) {
      const result = [];
      creationExpenditures.forEach((e) => {
        result.push(e.expenditure);
        result.push(...(e?.expenditure?.related_resources ?? []));
      });

      return result;
    } else {
      if (!group?.expenditures) return [];

      const result = [];

      group?.expenditures?.forEach((e) => {
        if (!e.related_work_id) {
          result.push(e);

          group.expenditures.forEach((m) => {
            if (m.related_work_id === e.id) {
              result.push(m);
            }
          });
        }
      });

      return result;
    }
  }, [group, isCreation, creationExpenditures]);

  const [initialGroup, setInitialGroup] = useState({
    name: group?.name,
    measure: group?.measure,
    count: group?.count ?? "0",
    amount: group?.amount,
    price: (group?.count && +group?.count !== 0 ? +group?.amount / +group?.count : 0).toString(),
    for_contractor: group?.for_contractor,
    payment_terms: group?.payment_terms || [],
    expenditures: initialExpenditures,
    totals: group?.totals ?? {
      all: initialExpenditures.reduce((acc, exp) => acc + +exp?.indicators?.estimate_amount ?? 0, 0).toString(),
      ...calculateTotalsFromExpenditures(initialExpenditures),
    },
  });

  const [partialEditedGroup, setPartialEditedGroup] = useState<Partial<IGroupInfo>>(() => initialGroup);

  const resetPartialEditedGroup = useCallback(() => {
    setPartialEditedGroup({
      name: group?.name,
      measure: group?.measure,
      count: group?.count ?? "0",
      amount: group?.amount ?? "0",
      price: (group?.count && +group?.count !== 0 ? +group?.amount / +group?.count : 0).toString(),
      for_contractor: group?.for_contractor,
      payment_terms: group?.payment_terms || [],
      expenditures: initialExpenditures,
      totals: group?.totals ?? {
        all: initialExpenditures.reduce((acc, exp) => acc + +exp?.indicators?.estimate_amount ?? 0, 0).toString(),
        ...calculateTotalsFromExpenditures(initialExpenditures),
      },
    });
  }, [group]);

  const expendituresEstimateAmount =
    group?.estimate_amount ?? initialExpenditures.reduce((acc, e) => acc + +e?.indicators?.estimate_amount ?? 0, 0);
  const groupEstimatePrice =
    partialEditedGroup?.count !== undefined
      ? expendituresEstimateAmount / partialEditedGroup?.count
      : group?.count
      ? expendituresEstimateAmount / group?.count
      : 0;

  const hasChanges = useMemo(
    () => !_.isEqual(initialGroup, { ...initialGroup, ...partialEditedGroup }),
    [partialEditedGroup, initialGroup]
  );

  const toggleEdit = useCallback(() => {
    let isNeedToReset = false;
    setIsEditing((prevState) => {
      if (prevState) {
        isNeedToReset = true;
      }
      return !prevState;
    });

    resetPartialEditedGroup();
  }, [group, resetPartialEditedGroup]);

  const onCreate = useCallback(() => {
    const data: ICreateGroupValues = {
      name: partialEditedGroup.name,
      measure: partialEditedGroup.measure,
      count: partialEditedGroup.count,
      price: partialEditedGroup.price,
      amount: partialEditedGroup.amount,
      for_contractor: partialEditedGroup.for_contractor,
      payment_terms: partialEditedGroup.payment_terms,
      expenditures: partialEditedGroup.expenditures,
      totals: partialEditedGroup.totals,
    };

    if (!partialEditedGroup) return;
    if (
      partialEditedGroup.for_contractor &&
      (partialEditedGroup.payment_terms?.some((term) => !term.payment_type || !term.percent) ||
        partialEditedGroup.payment_terms?.reduce((acc, term) => acc + parseFloat(term.percent), 0) !== 100)
    ) {
      message.warn("Укажите условия оплаты");
      return;
    }
    if (!partialEditedGroup?.name?.length) {
      message.warn("Укажите наименование");
      return;
    }
    if (!partialEditedGroup?.measure) {
      message.warn("Укажите ед. измерения");
      return;
    }
    if (!+partialEditedGroup?.count) {
      message.warn("Укажите количество");
      return;
    }
    if (!+partialEditedGroup?.amount) {
      message.warn("Укажите стоимость");
      return;
    }

    if (!data?.for_contractor) {
      data.payment_terms = [];
      setPartialEditedGroup((prevState) => ({ ...prevState, payment_terms: [] }));
    }

    handleCreate({ ...data, payment_terms_write: data.for_contractor ? data.payment_terms : [] });
  }, [handleCreate, partialEditedGroup]);

  const handleCompleteEdit = useCallback(() => {
    if (!partialEditedGroup) return;
    if (
      partialEditedGroup.for_contractor &&
      (partialEditedGroup.payment_terms?.some((term) => !term.payment_type || !term.percent) ||
        partialEditedGroup.payment_terms?.reduce((acc, term) => acc + parseFloat(term.percent), 0) !== 100)
    ) {
      message.warn("Укажите условия оплаты");
      return;
    }
    if (!partialEditedGroup?.name?.length) {
      message.warn("Укажите наименование");
      return;
    }
    if (!+partialEditedGroup?.count) {
      message.warn("Укажите количество");
      return;
    }
    if (!+partialEditedGroup?.amount) {
      message.warn("Укажите стоимость");
      return;
    }
    const submitData = { ...partialEditedGroup };
    if (!submitData?.for_contractor) {
      submitData.payment_terms = [];
      setPartialEditedGroup((prevState) => ({ ...prevState, payment_terms: [] }));
    }

    handleEdit?.(submitData, () => {
      resetPartialEditedGroup();
    });
    setIsEditing(false);
  }, [handleEdit, partialEditedGroup, resetPartialEditedGroup]);

  const handleChangePaymentTerms = useCallback((newTerms: any[]) => {
    setPartialEditedGroup((prevState) => (prevState ? { ...prevState, payment_terms: newTerms } : prevState));
  }, []);

  const calculateAmount = async (amount: string) => {
    if (isNaN(parseFloat(amount))) {
      return;
    }

    setPartialEditedGroup((prevState) => ({ ...prevState, amount, totals: { ...prevState.totals, all: amount } }));

    debouncedCalculateAllRequest(objectId, amount, partialEditedGroup.expenditures, (data) => {
      if (data) {
        setPartialEditedGroup((prev) => ({
          ...prev,
          expenditures: (prev?.expenditures ?? []).map((pe) => {
            const newExpenditurePart = data.expenditures.find((e) => e.id === pe.id);
            if (newExpenditurePart) {
              return { ...pe, estimated_cost: newExpenditurePart.estimated_cost };
            } else {
              return pe;
            }
          }),
          totals: data.totals,
        }));
      }
    });
  };

  const handleChangeGroupInfo = useCallback(
    (fieldName: keyof IGroupInfo, value: string | number | boolean, isNeedCalculate = true) => {
      if (fieldName === "amount" && isNeedCalculate) {
        calculateAmount(value);
      } else {
        setPartialEditedGroup((prevState) => ({ ...prevState, [fieldName]: value }));
      }
    },
    [calculateAmount]
  );

  const onNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChangeGroupInfo("name", e.target.value);
    },
    [handleChangeGroupInfo]
  );

  const onCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value || "0";

      if (v.split(".")?.[1]?.length && v.split(".")[1].length > 2) {
        return;
      }

      handleChangeGroupInfo("count", v);
      handleChangeGroupInfo("amount", (+v * +partialEditedGroup.price).toString());
    },
    [handleChangeGroupInfo, partialEditedGroup]
  );

  const onPriceChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value || "0";

      if (v.split(".")?.[1]?.length && v.split(".")[1].length > 2) {
        return;
      }

      handleChangeGroupInfo("price", v);
      handleChangeGroupInfo("amount", (+partialEditedGroup.count * +v).toString());
    },
    [handleChangeGroupInfo, partialEditedGroup]
  );

  const onAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value || "0";

      if (v.split(".")?.[1]?.length && v.split(".")[1].length > 2) {
        return;
      }

      handleChangeGroupInfo("amount", v);
      handleChangeGroupInfo("price", (+v / +partialEditedGroup.count).toString());
    },
    [handleChangeGroupInfo, partialEditedGroup]
  );

  const onMeasureChange = useCallback(
    (v: string) => {
      handleChangeGroupInfo("measure", v);
    },
    [handleChangeGroupInfo]
  );

  const onExpenditureEstimatedCostChange = useCallback((id: number, value: string) => {
    setPartialEditedGroup((prev) => {
      const idx = prev.expenditures?.findIndex((e) => e.id === id);

      if (idx === -1) {
        return prev;
      }

      const newExpendituresArr = prev.expenditures?.map((exp, expIdx) => {
        if (expIdx === idx) {
          return { ...exp, estimated_cost: value };
        } else {
          return exp;
        }
      });

      const newTotals = calculateTotalsFromExpenditures(newExpendituresArr ?? []);

      return {
        ...prev,
        price: (+newTotals.all / +prev.count).toString(),
        amount: newTotals.all,
        expenditures: newExpendituresArr,
        totals: newTotals,
      };
    });
  }, []);

  const onTotalChange = async (totalName: keyof Omit<IGroupTotals, "all">, value: string) => {
    const totals = {
      ...partialEditedGroup.totals,
      [totalName]: value,
    };

    // Суммируем перед запросом для облегчения расчетов на бэке
    if (totalName !== "all_without_rom") {
      totals.all_without_rom = (+totals.works + +totals.equipments + +totals.materials + +totals.services).toString();
    }

    setPartialEditedGroup((prevState) => ({ ...prevState, totals: { ...prevState.totals, ...totals } }));

    debouncedCalculateTotalsRequest(objectId, totals, totalName, partialEditedGroup.expenditures, (data) => {
      setPartialEditedGroup((prev) => ({
        ...prev,
        expenditures: (prev?.expenditures ?? []).map((pe) => {
          const newExpenditurePart = data.expenditures.find((e) => e.id === pe.id);
          if (newExpenditurePart) {
            return { ...pe, estimated_cost: newExpenditurePart.estimated_cost };
          } else {
            return pe;
          }
        }),
        totals: data.totals,
      }));

      handleChangeGroupInfo("amount", data.totals.all, false);
      handleChangeGroupInfo("price", (+data.totals.all / +partialEditedGroup.count).toString());
    });
  };

  const haveUngroupGroupsPermission = useCommonModulesPermissions({
    constructing: VIEW_BUILDING_UNGROUP_GROUPS,
    objects: VIEW_MANUFACTURING_PROJECT_UNGROUP_GROUPS,
  });

  const haveEditGroupsPermission = useCommonModulesPermissions({
    constructing: VIEW_BUILDING_EDIT_GROUPS,
    objects: VIEW_MANUFACTURING_PROJECT_EDIT_GROUPS,
  });

  if (!partialEditedGroup.expenditures?.length) return null;

  const isPaymentTermsVisible =
    (isEditing && partialEditedGroup?.for_contractor) || (!isEditing && group?.for_contractor);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Header
          isEdit={isEditing}
          name={partialEditedGroup.name ?? ""}
          onNameChange={onNameChange}
          expendituresCount={partialEditedGroup.expenditures.length}
        />
      }
      className={styles.modal}
      headerClassName={styles.header}
      titleClassName={styles.modalTitle}
      closeOnClickOutside={false}
    >
      <GroupInfo
        isEdit={isEditing}
        isMarketPrice={isMarketPrice}
        expendituresEstimateAmount={expendituresEstimateAmount}
        groupEstimatePrice={groupEstimatePrice}
        setIsMarketPrice={setIsMarketPrice}
        count={partialEditedGroup.count ?? "0"}
        measure={partialEditedGroup.measure ?? ""}
        price={partialEditedGroup.price ?? "0"}
        amount={partialEditedGroup.totals?.all ?? "0"}
        onCountChange={onCountChange}
        onPriceChange={onPriceChange}
        onAmountChange={onAmountChange}
        onMeasureChange={onMeasureChange}
      />

      <ExpendituresTable
        isEdit={isEditing}
        isMarketPrice={isMarketPrice}
        expenditures={partialEditedGroup.expenditures}
        onExpenditureEstimatedCostChange={onExpenditureEstimatedCostChange}
        isShowButtons={isShowButtons}
      />

      {(isEditing || isMarketPrice || isPaymentTermsVisible) && (
        <div className={styles.container}>
          {isMarketPrice && (
            <GroupAmounts isEdit={isEditing} group={partialEditedGroup} onTotalChange={onTotalChange} />
          )}

          {isEditing && (
            <ForContractorSwitcher
              onChange={handleChangeGroupInfo}
              value={partialEditedGroup?.for_contractor ?? false}
            />
          )}

          {isPaymentTermsVisible && (
            <PaymentTerms
              className={styles.paymentTerms}
              selectedTerms={partialEditedGroup?.payment_terms}
              disabled={!isEditing}
              changeSelectedTerms={handleChangePaymentTerms}
              isEditing={isEditing}
            />
          )}
        </div>
      )}
      <Footer
        isShowButtons={isShowButtons}
        haveUngroupGroupsPermission={haveUngroupGroupsPermission}
        haveEditGroupsPermission={haveEditGroupsPermission}
        isEditing={isEditing}
        isCreation={isCreation}
        hasChanges={hasChanges}
        toggleEdit={toggleEdit}
        handleCompleteEdit={handleCompleteEdit}
        handleUngroup={handleUngroup}
        onClose={onClose}
        onCreate={onCreate}
        isPending={isPending}
      />
    </Modal>
  );
};

export default React.memo(ExpenditureGroupModal);
