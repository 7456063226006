import cn from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteExpenditure, updateExpenditure } from "redux/modules/common/building/materials/specification";

import PopoverOverlay from "../../../../../../UI/_TODO/PopoverOverlay/PopoverOverlay";
import MatchingModal from "../../../../../../UI/atoms/MatchingModal/MatchingModal";
import ProductFilesModal from "../../../../../Requisition/components/ProductFilesModal/ProductFilesModal";
import AddFile from "../../../../components/AddFile";
import RequiredDays from "./components/RequiredDays/RequiredDays";
import { TableOslaLocal } from "_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";
import ProductInfo from "_LEGACY/UI/__trash/ProductInfo";
import { getMatchingType } from "components/UI/atoms/MatchingModal/utils";

import ConfirmationModal from "../../../../../../../entities/ConfirmationModal/ConfirmModal";
import Checkbox, { LabelPlacement } from "../../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import SpecificationAddRelationsModal from "../../widgets/SpecificationAddRelationsModal/SpecificationAddRelationsModal";

import { LOCALIZATION_CONFIG } from "constants/localization";

import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../../../../../utils/formatters/transformDigitToFinancial";

import Delete from "images/icons/Delete";

import styles from "./Expenditure.module.scss";

const Expenditure = (props) => {
  const { data, objectId, typeUrl, canEdit, isRetOwnMatCheckboxVisible, subSectionId } = props;

  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [countValue, setCountValue] = useState("count");
  const [priceValue, setPriceValue] = useState("price");
  const [expenditure, setExpenditure] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [idAddRelation, setIdAddRelation] = useState(false);
  const [sliderAddFile, setSliderAddFile] = useState(false);

  const countUpdate = React.useRef(
    _.debounce((id, data) => {
      dispatch(updateExpenditure(objectId, typeUrl, id, { count: data }));
    }, 1000)
  ).current;

  const priceUpdate = React.useRef(
    _.debounce((id, data) => {
      dispatch(updateExpenditure(objectId, typeUrl, id, { price: data }));
    }, 1000)
  ).current;

  const retOwnMatUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, typeUrl, id, { ret_own_mat: data }));
  };

  const handleRemoveExpenditure = (id) => {
    dispatch(deleteExpenditure(objectId, typeUrl, id));
    setIsDeleteModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setIsDeleteModalOpen(false);
  };

  const nameBlock = (cell, row) => {
    return typeUrl === "specification" ? (
      <div>
        <span style={styles.quontityCell}>{cell}</span>
        <div className={styles.labelBox}>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
          <ProductFilesModal
            isFileViewer
            filesCount={row.files_count}
            files={row.files}
            permissions={{
              addFiles: canEdit,
              deleteFiles: canEdit,
            }}
            productName={cell}
            expenditureId={row?.estimate_expenditure_id}
            isV2
            expenditureType={row?.estimate_expenditure?.expenditure_type}
          />
          {isRetOwnMatCheckboxVisible && !row?.estimate_expenditure?.is_default && (
            <PopoverOverlay
              openType="hover"
              placement="top"
              popoverBorderColor="primary"
              content={<div className={styles.popover}>От заказчика</div>}
            >
              <Checkbox
                labelPlacement={LabelPlacement.RIGHT}
                checked={row.ret_own_mat}
                onCheck={(e) => {
                  retOwnMatUpdate(row.id, e.target.checked);
                }}
                className={cn(styles.retOwnMat, { [styles.disabled]: !canEdit })}
                disabled={!canEdit}
              >
                Давальческий
              </Checkbox>
            </PopoverOverlay>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.contentBox}>
        <div className={styles.box1}>
          <span>{cell}</span>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
        </div>

        <ProductFilesModal
          files={row.files}
          permissions={{
            addFiles: canEdit,
            deleteFiles: canEdit,
          }}
          productName={cell}
          expenditureId={row?.estimate_expenditure_id}
          isV2
          expenditureType={row?.estimate_expenditure?.expenditure_type}
          isFileViewer
        />
      </div>
    );
  };

  const countBlock = (cell, row) => {
    return (
      <input
        disabled={!canEdit}
        className={styles.input}
        value={transformDigitToFinancial(cell, { withFloat: true, dropZeros: true })}
        onChange={(e) => {
          const value = transformDigitToFinancial(parseTransformedString(e.target.value), {
            withFloat: true,
            dropZeros: true,
          });
          e.target.value = value;
          setCountValue(value);
          countUpdate(row.id, value);
        }}
      />
    );
  };

  const priceBlock = (cell, row) => {
    const isLinkedToDefaultWork =
      row.estimate_expenditure?.is_default === true && row.estimate_expenditure?.expenditure_type === "work";
    return (
      <div className={styles.inputBox}>
        <input
          disabled={!canEdit}
          className={styles.input}
          value={transformDigitToFinancial(cell, { withFloat: true, dropZeros: true })}
          onChange={(e) => {
            setPriceValue(parseTransformedString(e.target.value));
            e.target.value = transformDigitToFinancial(parseTransformedString(e.target.value), {
              withFloat: true,
              dropZeros: true,
            });
            priceUpdate(row.id, parseTransformedNumber(e.target.value));
          }}
        />
        {typeUrl === "specification" && (
          <>
            <MatchingModal
              info={row}
              type={getMatchingType(row.type)}
              updateProducts={setExpenditure}
              objectId={objectId}
              renderCustomMatchingModal={(isOpen, onClose) => (
                <SpecificationAddRelationsModal
                  isOpen={isOpen}
                  onClose={onClose}
                  itemId={row.id}
                  subSectionId={subSectionId}
                />
              )}
              customOpenBehavior={(info) => {
                if (isLinkedToDefaultWork) {
                  return "info";
                }
                if (!info.estimate_expenditure) {
                  return "matching";
                }
                if (info.created_from_estimate) {
                  return "info";
                }
                return "matching";
              }}
              customProductInfoModalProps={
                isLinkedToDefaultWork && {
                  title: "По проекту",
                  labelBudget: "Бюджет по проекту: ",
                  labelCount: "Кол-во по проекту: ",
                }
              }
            />
            <RequiredDays objectId={objectId} itemId={row?.id} delayDays={row?.delay_days} />
          </>
        )}
        {canEdit && (
          <div
            className={styles.checkFile}
            onClick={() => {
              setExpenditure(row);
              setIsDeleteModalOpen(true);
            }}
          >
            <Delete />
          </div>
        )}
      </div>
    );
  };

  const specificationNumberFormatter = (_, row) => row?.estimate_expenditure?.number;

  return (
    <div className={styles.tableWrapper}>
      <TableOslaLocal
        data={data}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
      >
        <TableHeader
          style={{ width: "2%", textAlign: "center", paddingLeft: "1.25rem" }}
          cellStyle={{ textAlign: "center", width: "2%", paddingLeft: "1.25rem" }}
          data={"number"}
          formatter={specificationNumberFormatter}
        >
          <div className={styles.tableTextBold}>№</div>
        </TableHeader>
        <TableHeader
          style={{ width: "40%", textAlign: "left", padding: "0 3.88rem" }}
          cellStyle={{ textAlign: "left", width: "40%", padding: "1rem 3.88rem" }}
          data={"name"}
          formatter={nameBlock}
        >
          <div className={styles.tableTextBold}>Наименование</div>
        </TableHeader>
        <TableHeader
          data={"measure"}
          style={{ width: "7%", textAlign: "center" }}
          cellStyle={{ textAlign: "center", width: "7%" }}
        >
          <div className={styles.tableTextBold}>Ед. изм.</div>
        </TableHeader>
        <TableHeader
          data={countValue}
          formatter={countBlock}
          style={{ width: "9.5%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "9.5%" }}
        >
          <div className={styles.tableTextBold}>Количество</div>
        </TableHeader>
        <TableHeader
          data={priceValue}
          formatter={priceBlock}
          style={{ width: "20%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "20%" }}
        >
          <div className={styles.tableTextBold}>Цена&nbsp;{LOCALIZATION_CONFIG.currency}</div>
        </TableHeader>
      </TableOslaLocal>
      {isDeleteModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={closeConfirmationModal}
          action={() => handleRemoveExpenditure(expenditure.id)}
        >
          Удалить {expenditure.name}?
        </ConfirmationModal>
      )}
      {/* <AddRelationToProduct
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false)}
        objectId={objectId}
        editable={idAddRelation.editable}
      /> */}
      <ProductInfo
        isOpen={isInfoModalOpen}
        closeModal={() => setIsInfoModalOpen(false)}
        objectId={objectId}
        chosenMatchingProduct={expenditure}
        openRelationToProduct={() => {
          setIdAddRelation({ id: isInfoModalOpen?.id });
        }}
      />
      <AddFile
        objectId={objectId}
        isOpen={sliderAddFile}
        closeFunModal={() => setSliderAddFile(false)}
        obj={expenditure}
      />
    </div>
  );
};

export default Expenditure;
